/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


var normas = function () {
    var appnormas = null;

    /**
     * Para tratamento de eventos...
     * */
    var eventHandler = function () {
        try 
        {
            $(".job-option .job-option-title",appnormas).click(function (){
               if($(this).hasClass("active")){
                   $(".job-seta",this).removeClass("active");
                   $(this).removeClass("active");
               }else
               {
                   $(this).addClass("active");
                   $(".job-seta",this).addClass("active");
               }
            });
            
            
        } catch (e) {
            console.log('[ERRO:normas]: ' + e);
        }
    };
    return {
        init: function (_appnormas) {
            appnormas = _appnormas;
            eventHandler();
        }
    };
}();



